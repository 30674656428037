import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import ColumnaIzq from "../ColumnaIzq/ColumnaIzq";
import ContenidoCentral from "../ContenidoCentral/ContenidoCentral";
import setContent from "../../actions/setContent";
import Banner from "../Banner/Banner";
import flecha from "./felcha izquierda.svg";
import style from "./Specialty.module.css";
import { ContentLeft } from "../ContentLeft/ContentLeft";
import { ContentRight } from "../ContentRight/ContentRight";
import IndYPrep from "../Laboratories/IndYPrep/IndYPrep";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Helmet } from "react-helmet";
import { useData } from "../../hooks/api";

const Specialty = () => {
  const navigate = useNavigate();
  const [currentData, setCurrentData] = useState(null); 
  const [preparations, setPreparations] = useState("");
  const [preparationsLab, setPreparationsLab] = useState("");
  const { name, section, area, type, id } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  // Obtén "sec" de la URL primero. Si no existe, usa searchParams como respaldo
  const secFromUrl = type?.toLowerCase(); // Esto viene del path que indica "pediatricos" o "adultos"
  const sec = 
    secFromUrl === "adultos" || secFromUrl === "pediatricos"
      ? secFromUrl === "adultos" ? "Adultos" : "Pediátricos"
      : searchParams.get("sec") || "Adultos"; 


  const { data: specialtiesData } = useData("specialtiesList");
  const { data: laboratoriesData } = useData("laboratorios");
  const { data: preparationsSpecialtiesData } = useData("preparationsSpecialties");
  const { data: preparationsLabsData } = useData("preparationsLabs");

  const path =
    currentData?.kids || currentData?.adults
      ? "especialidades"
      : currentData?.principal
        ? "unidadesAsistenciales"
        : currentData?.tag
          ? "general"
          : "laboratorios";

  // Firebase Analytics
  const analytics = getAnalytics();
  logEvent(analytics, "screen_view", {
    firebase_screen: `${path}: ${name}`,
    firebase_screen_class: "Especialidades, laboratorios y unidades asistenciales",
  });

  // Filtrado de laboratorios basado en 'sec' (Adultos o Pediátricos)
  const currentSpecialty = useCallback(() => {
    
    if (name && specialtiesData) {
      const specialties = specialtiesData.filter(
        (specialty) => specialty.name === name
      );
      if (specialties.length > 0) {
        let typeParams = sec === "Adultos" ? "true" : "false"; // Usamos sec para determinar si es "Adultos" o "Pediátricos"
        const filter = specialties.filter(
          (specialty) => specialty.adults === typeParams
        );
        setCurrentData(filter[0] || specialties[0]);
      }
    }
  }, [name, specialtiesData, sec]);

  const currentLab = useCallback(() => {
    if (name && laboratoriesData) {
      const labs = laboratoriesData.filter((lab) => lab.name === name);
      if (labs.length > 0) {
        setCurrentData(labs[0]);
      }
    }
  }, [name, laboratoriesData]);

  const preparationsSpecialties = useCallback(() => {
    if (id && preparationsSpecialtiesData) {
      const filter = preparationsSpecialtiesData.filter((e) =>
        e.specialties.some((r) => r === id)
      );
      setPreparations(filter);
    }
  }, [id, preparationsSpecialtiesData]);

  const preparationsLabs = useCallback(() => {
    if (id && preparationsLabsData) {
      const filter = preparationsLabsData.filter((e) =>
        e.laboratories.some((r) => r === id)
      );
      setPreparationsLab(filter);
    }
  }, [id, preparationsLabsData]);

  const filter = useCallback(() => {
    const filterSec = section
      ? currentData?.sections?.filter((e) => e.name === section)
      : null;
    dispatch(setContent(filterSec && filterSec[0]?.content));
  }, [currentData, dispatch, section]);

  const capitalize = (name) => {
    if (name === "pediatricos") {
      return "Pediátricos";
    } else {
      if (name) {
        return name[0]?.toUpperCase() + name?.substring(1);
      }
    }
  };

  // Filtrar datos basados en el ID y sec para actualizar currentData
  useEffect(() => {
    if (specialtiesData && id) {
      const specialtyById = specialtiesData.find(
        (specialty) => specialty.id === id && specialty.adults === (sec === "Adultos" ? "true" : "false")
      );
      if (specialtyById) {
        setCurrentData(specialtyById);
      }
    }
  }, [id, sec, specialtiesData]);

  useEffect(() => {
    currentSpecialty();
    currentLab();
  }, [name, type, specialtiesData, laboratoriesData, currentSpecialty, currentLab]);

  useEffect(() => {
    preparationsSpecialties();
    preparationsLabs();
  }, [id, preparationsSpecialtiesData, preparationsLabsData, preparationsSpecialties, preparationsLabs]);

  useEffect(() => {
    filter();
  }, [currentData, filter]);

  useEffect(() => {
    return () => {
      setCurrentData("");
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>CURF - Especialidades y profesionales</title>
        <meta
          name="description"
          content="Vanguardia y tecnología acompañan a las más de 60 especialidades médicas, con profesionales altamente calificados que garantizan el lado humano de la medicina."
        />
      </Helmet>
      <div className={style.container__main}>
        <Banner
          image={currentData?.img}
          title={
            name
              ? name === "Diagnóstico por imágenes"
                ? "Diagnóstico por Imágenes"
                : name
              : area === "unidadesAsistenciales"
                ? "Unidades asistenciales"
                : capitalize(area)
          }
          type={path !== "laboratorios" ? capitalize(type) : ""}
        />
        <div className={style.container__flecha_home}>
          <div className={style.container__flecha_home_content}>
            <img
              src={flecha}
              alt="Flecha"
              className={style.flecha__home}
              onClick={() => navigate(-1)}
            />

            <span onClick={() => navigate(-1)}>Volver</span>
          </div>
        </div>
        <div className={style.container__content}>
          {name ? (
            <ContentLeft
              path={path}
              sections={currentData?.sections}
              sites={currentData?.sites}
              preparations={path === "especialidades" ? preparations : preparationsLab}
              type={type}
              id={id}
            />
          ) : (
            <ColumnaIzq sec={area} />
          )}
          {name && section !== "Indicaciones y preparaciones" ? (
            <ContentRight currentSpecialty={currentData} path={path} id={id} />
          ) : section === "Indicaciones y preparaciones" ? (
            path === "laboratorios" ? (
              <div className={style.columnader__container_main}>
                <div className={style.columnader__container_content}>
                  <IndYPrep preps={preparationsLab} type="lab" />
                </div>
              </div>
            ) : (
              <div className={style.columnader__container_main}>
                <div className={style.columnader__container_content}>
                  <IndYPrep preps={preparations} type="special" />
                </div>
              </div>
            )
          ) : (
            <ContenidoCentral areaSearch={specialtiesData} category={sec} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Specialty;
